import api from '../../services/api'
import { userFeedbackService } from '../../services';
import { customerService } from '../../services';
import { skillService } from '../../services';
import { opportunityState } from '../../services';

const API_URL = process.env.VUE_APP_AWS_SERVICE_OPPORTUNITY_URL + '/api/contract/';

const ContractModule = {
    state: () => ({
        contracts: [],
        contract: null
    }),
    mutations: {
        setContract: (state, contract) => {
            state.contract = contract
            api.defaults.headers["Contract-Name"] = contract.name
            // contract is source of truth for all store modules
            // if the contract name changes, reload customers, states and skills once
            customerService.getAll();
            opportunityState.getAll();
            skillService.getAll();
        },
        setContracts: (state, contracts) => {
            state.contracts = contracts
        }
    },
    getters: {
        getContract(state) {
            return state.contract
        },
        getContracts(state) {
            return state.contracts
        },
        isDisabledContract(state) {
            //check if the contract name is the signed one, could be extendable for further signed contracts
           return (state.contract.name === 'Voice 20259') ? true:false;
          },
    },
    actions: {
        async getAll({ state, commit }) {
            try {
                let response = await api.get(API_URL)
                commit("setContracts", response.data)
                if(state.contract == null) {
                    let filteredContracts = response.data.filter(contract => contract.name.includes("Netzwerk"))
                    if(filteredContracts.length > 0)
                        commit("setContract", filteredContracts[0])
                }
                return response.data
            } catch(error) {
                console.error(error);
                userFeedbackService.error(error);
            }
        },
        /* eslint-disable no-unused-vars */
        save({ state }, contract) {
            return api.post(API_URL, contract)
        },
        /* eslint-disable no-unused-vars */
        delete({ state }, contract) {
            return api.request({
                url:  API_URL,
                method: 'delete',
                data: contract
            })
        }        
    }
}

export default ContractModule