<template>
  <div>
    <amplify-authenticator>
      <!--eslint-disable-next-line vue/no-deprecated-slot-attribute ! -->
      <amplify-sign-in slot="sign-in" :hide-sign-up="true"> </amplify-sign-in>
    </amplify-authenticator>
    <v-app v-if="$store.getters.getLoadState">
      <AppBar></AppBar>
      <v-main>
        <v-fade-transition mode="out-in">
          <router-view />
        </v-fade-transition>
        <UserFeedback />
        <sx-confirm-dialog />
      </v-main>
      <HomeFooter />
    </v-app>
  </div>
</template>

<script>
import Auth from "@aws-amplify/auth";
import { Hub } from "@aws-amplify/core";
import { appService } from "./services/service/api.app.service";
import { mapActions } from "vuex";

export default {
  name: "App",
  metaInfo: {
    title: "App",
    titleTemplate: "%s | SpyderX",
    htmlAttrs: { lang: "en" },
    meta: [
      { charset: "utf-8" },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1",
      },
    ],
  },

  components: {
    AppBar: () => import("@/components/header/AppBar"),
    HomeFooter: () => import("@/components/footer/Footer"),
    UserFeedback: () => import("@/components/UserFeedback"),
  },

  methods: {
    ...mapActions("user", ["setItems"]),

    async getToken() {
      let token = (await Auth.currentSession()).getIdToken().getJwtToken();
      this.$store.commit("user/setAuthToken", token);
      return token;
    },

    setLogin() {
      this.getToken().then((token) => {
        this.$store.commit("user/setAuthToken", token);
        this.$http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      });

      this.getCurrentAuthenticatedUser().then((groups) => {
        this.setItems(groups);
        appService.getAppContext();
      });
    },

    async getCurrentAuthenticatedUser() {
      const user = await Auth.currentAuthenticatedUser();
      const groups =
        user.signInUserSession.accessToken.payload["cognito:groups"];
      // issue with the loading of the content. setloadstate need to be in an async method
      this.$store.dispatch("setLoadState", true);
      this.setUserInfo(user, groups);

      return groups;
    },

    setUserInfo(user, groups) {
      const firstNameLetter = user.attributes.name
        .substring(0, 1)
        .toUpperCase();
      const lastNameLetter = user.attributes.family_name
        .substring(0, 1)
        .toUpperCase();
      this.$store.commit("setUserInfo", {
        firstname: user.attributes.name,
        lastname: user.attributes.family_name,
        fullname: `${user.attributes.family_name}, ${user.attributes.name}`,
        email: user.attributes.email,
        avatar: firstNameLetter + lastNameLetter,
        groups: groups,
      });
    },
  },
  mounted() {
    try {
      Auth.currentUserInfo().then((user) => {
        if (user != null) {
          this.setLogin();
        } else {
          Hub.listen("auth", (data) => {
            if (data.payload.event == "signIn") {
              this.setLogin();
              location.reload();
            }
            // Amplify error messages
            if (data.payload.event === "signIn_failure") {
              data.payload.data.message =
                "Der Benutzername oder das Kennwort ist falsch!";
            }
            if (data.payload.event === "completeNewPassword_failure") {
              data.payload.data.message =
                "Das neue Passwort muss mind. 8 Zeichen lang sein, mind. ein Sonderzeichen, Zahlen, Großbuchstaben, und Kleinbuchstaben enthalten.";
            }
          });
        }
      });
    } catch (e) {
      console.error(e);
    }
  },
};
</script>

<style lang="scss">
@import "../node_modules/@ag-grid-community/core/dist/styles/ag-grid.min.css";
@import "../node_modules/@ag-grid-community/core/dist/styles/ag-theme-alpine.min.css";

#app {
  background: initial !important;
}

html,
body {
  overflow: auto !important;
}

:root {
  --amplify-secondary-tint: #ff6a6a;
}

.extra-padding {
  padding-bottom: 96px !important;
  padding-top: 96px !important;

  @media screen and (max-width: 959px) {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }
}

.main {
  border-radius: 4px;
  background-color: #f9f9fceb !important;
}

a {
  text-decoration: none !important;
}

.pointer {
  cursor: pointer;
}

.sx-padding {
  padding: 25px;
}

.sx-flex-buttons {
  display: flex;
}

.sx-flex-buttons > button {
  margin-left: 10px;
}

.sx-flex-buttons > button:first-child {
  margin-left: 0px !important;
}

.sx-table-cell-bool-true {
  width: 10px;
  height: 10px;
  margin-top: 15px;
  border-radius: 50%;
  background-color: #7fd47f;
}

.sx-table-cell-bool-false {
  width: 10px;
  height: 10px;
  margin-top: 15px;
  border-radius: 50%;
  background-color: #d47f7f;
}

.icon-center {
  margin: 0 auto !important;
  margin-bottom: 9px !important;
}

.sx-vertical-layout {
  display: flex;
  flex-direction: column;
}
</style>
