
export const group = {
    namespaced: true,
    state: {
        groups: []
    },
    mutations: {
        setGroups(state, groups) {
            state.groups = groups
        }
    },
    getters: {
        getGroups(state) {
            return state.groups
        }
    },
    actions: { }    
}
