// Manufacturer Service Functions

import api from '../api'
import store from '../../store'
import { userFeedbackService } from '..'

export const oppService = {
    getAll,
    getByOpportunityId,
    getOne,
    post,
    del,
    deleteById,
    postNewVersion,
    postClone,
    cleanOrderedServices,
    setOrderedServices
}


const API_URL = process.env.VUE_APP_AWS_SERVICE_SERVICE_URL + '/api/oppservice/';

// HTTP GET to fetch all oppServices and returns them if they exists.
// Otherwise it will return an empty array.
async function getAll() {
    try {
        let response = await api.get(API_URL)
        store.dispatch('oppService/setItems', response.data);
    } catch (error) {
        console.error(error);
        userFeedbackService.error(error);
    }
}

/**
 * Get Services by Opportunity Id or return null
 * @param {*} id the OpportunityId
 * @returns Services of an Opportunity or null
 */
async function getByOpportunityId(id) {
    if (id == null) return null;
    try {
        var response = await api.get(API_URL.concat("opportunity/").concat(id))
        return response.data;
    } catch (error) {
        console.error(error);
        userFeedbackService.error(error);
    }

    return null;
}

// HTTP GET to fetch a single oppService and returns it if it exists.
// Otherwise it will return null.
async function getOne(id) {
    let result = null;
    await api.get(API_URL.concat(id))
        .then((response) => {
            result = response.data;
        })
        .catch((error) => {
            console.error(error);
            userFeedbackService.error(error);
        })

    return result;
}

// HTTP POST to create a oppService and returns the result.
async function post(oppService) {
    let payload = Object.assign({}, oppService);
    let result = null

    await api.post(API_URL, payload)
        .then((response) => {
            result = response.data;
            store.dispatch('oppService/addOppService', result);
            userFeedbackService.success('OppService created successfully');
        })
        .catch((error) => {
            console.error(error);
            userFeedbackService.error('Error creating OppService: ' + error);
        })

    return result;
}

async function postNewVersion(opportunityServiceId, opportunityId) {
    let result;

    const newVersionUri = "/newversion?oppid="

    await api.post(`${API_URL}${opportunityServiceId}${newVersionUri}${opportunityId}`)
        .then((response) => {
            result = response.data;

            if (result) {
                store.dispatch('oppService/addOppService', result);
                userFeedbackService.success('OpportunityService duplicated successfully');
            }
        })
        .catch((error) => {
            //console.error(error);
            userFeedbackService.error('Error duplicating OpportunityService: ' + error);
        })

    return result;
}

async function postClone(opportunityServiceId, opportunityId) {
    let result;

    const cloneUri = "/clone?oppid="

    await api.post(`${API_URL}${opportunityServiceId}${cloneUri}${opportunityId}`)
        .then((response) => {
            result = response.data;

            if (result) {
                store.dispatch('oppService/addOppService', result);
                userFeedbackService.success('OpportunityService cloned successfully');
            }
        })
        .catch((error) => {
            //console.error(error);
            userFeedbackService.error('Error cloning OpportunityService: ' + error);
        })

    return result;
}

// HTTP DELETE to delete a oppService and returns the result.
async function del(id) {
    let result = null
    await api.delete(API_URL.concat(id))
        .then((response) => {
            result = response.data;
            userFeedbackService.error('Reset or Error - Delete element!');
        })
        .catch((error) => {
            console.error(error);
            userFeedbackService.error('Error deleting OppService: ' + error);
        })

    return result;
}

/**
 * Delete a Service by Service Id
 * @param {*} id The Id of the service to delete
 * @returns result body or null
 */
async function deleteById(id) {
    try {
        return await api.delete(API_URL.concat(id))
    } catch (error) {
        console.error(error);
        userFeedbackService.error('Error deleting OppService: ' + error);
    }

    return null;
}


async function setOrderedServices(id, orderedservices) {
    let result = null;

    await api.patch(`${API_URL}${id}/setorderedservices`, orderedservices)
        .then((response) => {
            result = response.data;

            if (result) {
                store.dispatch('oppService/editOppServiceItem', result);
                userFeedbackService.success('OpportunityService edit successfully');
            }
        })
        .catch((error) => {
            //console.error(error);
            userFeedbackService.error('Error cloning OpportunityService: ' + error);
        })

    return result;

}

async function cleanOrderedServices(id) {
    try {
        return await api.patch(`${API_URL}${id}/cleanorderedservices`)
    } catch (error) {
        console.error(error);
        // userFeedbackService.error('Error: ' + error);
    }

    return null;
}