// Manufacturer Service Functions

import api from '../api'
import store from '../../store'
import { userFeedbackService } from '..'

export const customerService = {
    getAll,
    getOne,
    post,
    del,
    excel
}

const API_URL = process.env.VUE_APP_AWS_SERVICE_CUSTOMER_URL + '/api/customer/';

// HTTP GET to fetch all customers and returns them if they exists.
// Otherwise it will return an empty array.
async function getAll() {
    try {
        let response = await api.get(API_URL)
        let customers = await store.dispatch("sortByKey", { data: response.data, key: 'name' })
        store.dispatch('customer/setItems', customers);
    } catch (error) {
        console.error(error);
        userFeedbackService.error(error);
    }
}

// HTTP GET to fetch a single customer and returns it if it exists.
// Otherwise it will return null.
async function getOne(id) {
    let result = null;
    await api.get(API_URL.concat(id))
        .then((response) => {
            result = response.data;
        })
        .catch((error) => {
            console.error(error);
            userFeedbackService.error(error);
        })

    return result;
}

async function excel(excelFile) {
    let result = null;
    await api.post(API_URL + 'excel/', excelFile)
        .then(async (response) => {
            result = response.data;

            let customers = await store.dispatch("sortByKey", { data: response.data, key: 'name' })
            store.dispatch('customer/setItems', customers);

            userFeedbackService.success('Die Daten wurden erfolgreich importiert.');
        })
        .catch((error) => {
            console.error(error);
            result = error.response;
            userFeedbackService.error(error.response.data);
        })

    return result;
}

// HTTP POST to create a customer and returns the result.
function post(customer) {
    let payload = Object.assign({}, customer);
    return api.post(API_URL, payload)
}


// HTTP DELETE to delete a customer and returns the result.
function del(id) {
    return api.request({
        url: API_URL,
        method: 'delete',
        data: {
            id: id
        }
    })
}