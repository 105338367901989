// User Service Functions

import api from '../api'
import store from '../../store'
import { userFeedbackService } from '..'

const API_APP_URL = process.env.VUE_APP_AWS_SERVICE_API_URL + '/api/user/';
const API_APP_URL_GROUPS = process.env.VUE_APP_AWS_SERVICE_API_URL + '/api/group/';

export const userService = {
    // HTTP GET to fetch all users and returns them if they exist.
    // Otherwise it will return an empty array.
    getAll: async () => {
        await api.get(API_APP_URL)
            .then((response) => {
                store.commit('user/setUsers', response.data);
            })
            .catch((error) => {
                console.error(error);
                userFeedbackService.error(error);
            })
    },

    // GET all existing groups
    getAllGroups: async () => {
        await api.get(API_APP_URL_GROUPS)
            .then((response) => {
                store.commit('group/setGroups', response.data);
            })
            .catch((error) => {
                console.error(error);
                userFeedbackService.error(error);
            })
    },

    create: (newUser) => {
        return api.post(API_APP_URL, newUser)
    },

    // HTTP POST to update a user
    post: async (user, userUnmodified) => {

        return new Promise((resolve, reject) => {
            var promises = []
            if (user.enabled != userUnmodified.enabled)
                promises.push(api.post(API_APP_URL + user.username + ((user.enabled) ? '/enable' : '/disable')))

            if (user.groups != userUnmodified.groups) {
                // groups that have been removed
                userUnmodified.groups.forEach(async g1 => {
                    if (user.groups.find(g2 => g1.name == g2.name) == null)
                        promises.push(await api.delete(API_APP_URL + user.username + '/' + g1.name))
                })

                // groups that have been added
                user.groups.forEach(g1 => {
                    if (userUnmodified.groups.find(g2 => g1.name == g2.name) == null)
                        promises.push(api.post(API_APP_URL + user.username + '/' + g1.name))
                })
            }

            Promise.all(promises).then(r => {
                return resolve(r)
            })
                .catch(error => {
                    return reject(error)
                })
        })
    },

    put: async (user) => {
        api.put(API_APP_URL + user.username + '/' + user.phoneNumber)
            .catch(error => {
                console.err(error)
            })
    },

    // HTTP DELETE to delete a user
    del: (username) => {
        return api.delete(API_APP_URL + username)
    },

}