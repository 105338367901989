// Vuex ChangeLog Module

const state = {
    items: [],
    loading: false
}

const actions = {
    setItems({ commit }, items) {
        commit('setItems', items);
    },
    setLoading({ commit }, loading) {
        commit('setLoading', loading)
    }
}

const getters = {
    users(state) {
        return state.items
            .map(x => x.firstName + ' ' + x.lastName)
            .filter((fullName, index, array) => array.indexOf(fullName) === index)
            .sort();
    },
    loading(state) {
        return state.loading
    }
}

const mutations = {
    setItems(state, items) {
        state.items = items;
    },
    setLoading(state, loading) {
        state.loading = loading
    }
}

export const changelog = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state    
}