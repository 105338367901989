// Vuex Customer Module

const state = {
    // CAUTION! The customer list is just for the prototype
    services: [],
}

const actions = {
    
}

const getters = {
    getCustomers(state){
        return state.services;
    }
}

const mutations = {
    
}


export const service = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state    
}