// Vuex Customer Module

const state = {
    customers: []
}

const actions = {
    setItems({ commit }, items) {
        commit('setItems', items);
    },
    // Ugly name. We should split up both modules
    deleteCustomer({ commit }, item) {
        commit('deleteCustomer', item);
    },

    setCustomer({ commit }, items) {
        commit('setCustomer', items);
    },
    editCustomerItem({ commit }, item) {
        commit('editCustomerItem', item);
    }
}

const getters = {

    customers(state) {
        return state.customers.sort((a, b) => a.id - b.id)
    },

    getCustomers(state) {
        return state.customers;
    },

    getCustomer: (state) => (name) =>{
        return state.customers.find(customer =>customer.name === name)
    }
       


}

const mutations = {
    setItems(state, items) {
        state.customers = items;
    },
    /*
    setCustomer(state, { id, customer, description, endDate, customerItems, owner, startDate, project, pulseId }) {
        state.customer.id = id;
        state.customer.customer = customer;
        state.customer.description = description;
        state.customer.endDate = endDate;
        state.customer.customerItems = customerItems;
        state.customer.owner = owner;
        state.customer.startDate = startDate;
        state.customer.project = project;
        state.customer.pulseId = pulseId;
    },
    */
    deleteCustomer(state, item) {
        const index = state.customers.indexOf(item);
        state.customers.splice(index, 1);
    },

    editCustomerItem(state, item) {
        const index = state.customers.customerItems.map(f => f.id).indexOf(item.id);
        state.customers.customerItems[index].id = item.id;
        state.customers.customerItems[index].checkedIn = item.checkedIn;
        state.customers.customerItems[index].expectedDate = item.expectedDate;
        state.customers.customerItems[index].customerId = item.customerId;
        state.customers.customerItems[index].occurence = item.occurence;
        state.customers.customerItems[index].product = item.product;
        state.customers.customerItems[index].productId = item.productId;
        state.customers.customerItems[index].quantity = item.quantity;
    }
}


export const customer = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
}