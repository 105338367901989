// Vuetify Documentation https://vuetifyjs.com

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import IconEmailDv from '@/components/content/EmailIconDv.vue'

Vue.use(Vuetify)

// Set the theme colors
// Only use official CC CI Colors
export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#24387f', // Dark Blue
        secondary: '#009ddc', // Ligth Blue
        accent: '#8fd400', // Green
        info: '#009ddc', // Ligth Blue
        success: '#8fd400', // Green
        warning: '#ff7900', // Orange
        error: '#f12938' // Red
      },
      dark: {},
    },
  },
  icons: {
    values: {
      iconEmailDv: { // name of custom icon
        component: IconEmailDv, // custom component
      },
    },
  },
})