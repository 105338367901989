// Vuex Skill Module
const state = {
    skills: [],
    currentSum: 0,
}


const getters = {

    skills(state) {
        return state.skills
    },
    getSkillsName(state) {
        return state.skills.sort((a, b) => a.id - b.id).
            map(skill => skill.name)
    },

    getSkillByName: (state) => (name) => {
        let skills = state.skills.filter(skill => skill.name == name)
        if (skills.length > 0)
            return skills[0]
    },

    servicePrice: (state) => (service, newPricePerDay) => {
        let price = 0;

        if (service.days <= 40) {
            state.skills.filter((skill) => {
                if (skill.name === service.skillName) {
                    if (newPricePerDay) {
                        return (price =
                            (newPricePerDay * service.days));
                    } else {
                        return (price =
                            (skill.lessThan40PT * service.days));
                    }
                }
            });
        } else if (service.days > 160) {
            state.skills.filter((skill) => {
                if (skill.name === service.skillName) {
                    if (newPricePerDay) {
                        return (price =
                            (newPricePerDay * service.days));
                    }
                    else {
                        return (price =
                            (skill.moreThan160PT * service.days));
                    }
                }
            });
        } else {
            state.skills.filter((skill) => {
                if (skill.name === service.skillName) {
                    if (newPricePerDay) {
                        return (price =
                            (newPricePerDay * service.days));
                    } else {
                        return (price =
                            (skill.between41To160PT * service.days));
                    }

                }
            });

        }

        return price;
    },

    getPrice: (state) => (service) => {
        let price = 0;
        if (service.days <= 40) {
            state.skills.filter((skill) => {
                if (skill.name === service.skillName) {
                    price = skill.lessThan40PT
                }
            });
        } else if (service.days > 160) {
            state.skills.filter((skill) => {
                if (skill.name === service.skillName) {
                    price = skill.moreThan160PT
                }
            });
        } else {
            state.skills.filter((skill) => {
                if (skill.name === service.skillName) {
                    price = skill.between41To160PT
                }
            });
        }
        return price
    },

}

const mutations = {
    setItems(state, items) {

        state.skills = items;
    },

    deleteSkill(state, item) {
        const index = state.skills.indexOf(item);
        state.skills.splice(index, 1);
    },

    editSkillItem(state, item) {
        const index = state.skills.skillItems.map(f => f.id).indexOf(item.id);
        state.skills.skillItems[index].id = item.id;
        state.skills.skillItems[index].checkedIn = item.checkedIn;
        state.skills.skillItems[index].expectedDate = item.expectedDate;
        state.skills.skillItems[index].skillId = item.skillId;
        state.skills.skillItems[index].occurence = item.occurence;
        state.skills.skillItems[index].product = item.product;
        state.skills.skillItems[index].productId = item.productId;
        state.skills.skillItems[index].quantity = item.quantity;
    }
}

const actions = {
    setItems({ commit }, items) {
        commit('setItems', items);
    },
    // Ugly name. We should split up both modules
    deleteSkill({ commit }, item) {
        commit('deleteSkill', item);
    },

    setSkill({ commit }, items) {
        commit('setSkill', items);
    },
    editSkillItem({ commit }, item) {
        commit('editSkillItem', item);
    }

};



export const skill = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
}