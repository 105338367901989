// Imports
import Vue from 'vue'
import store from '../store'
import Router from 'vue-router'
import Auth from '@aws-amplify/auth';

Vue.use(Router)

//import store from '@/store'

const router = new Router({
    base: process.env.BASE_URL,
    scrollBehavior: (to, from, savedPosition) => {
        if (to.hash) return { selector: to.hash }
        if (savedPosition) return savedPosition

        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '/',
            name: 'Home',
            component: () => import('../views/Home.vue'),
        },
        {
            path: '/admin',
            name: 'Admin',
            component: () => import('../views/AdminPage.vue'),
        },
        {
            path: '/about',
            name: 'About',
            component: () => import('../views/About.vue'),
        },
        {
            path: '/changelog',
            name: 'Changelog',
            component: () => import('../views/Changelog.vue'),
        },
        {
            path: '*',
            name: 'FourOhFour',
            component: () => import('../views/404.vue'),
        },
    ],
})

router.beforeEach(async (to, from, next) => {
    if(store.getters["user/getAuthToken"] == null) {
        let token = (await Auth.currentSession())
            .getIdToken()
            .getJwtToken();
        store.commit("user/setAuthToken", token)
        if(store.getters["contract/getContract"] == null)
            await store.dispatch("contract/getAll")
    }

    if(to.name == 'Admin')
        if(store.getters["user/getAdminGroup"] != null) {
            next()
            return
        } else
            next({ name: 'Home' })

    next()
})

/*
router.beforeEach(async (to, from, next) => {
    if (store.state.userInfo.groups.length == 0) {
        await getUser();
    }
    switch (to.name) {
        case 'ManageForecasts':
        case 'ForecastDetails':
            if (!store.getters.hasPermission('Read forecasts')) next({ name: 'Home' });
            break;
        case 'ForecastSummary':
            if (!store.getters.hasPermission('Read summary')) next({ name: 'Home' });
            break;
        case 'Product':
            if (!store.getters.hasPermission('Write Product')) next({ name: 'Home' });
            break;
        case 'Changelog':
            if (!store.getters.hasPermission('Read changelog')) next({ name: 'Home' });
            break;
    }
    next();
})

async function getUser() {
    // call getUserProfile function to get the current user profile and save in store
    /*
    let currentuser = authentication.getUserProfile();
    store.commit("setUserInfo", {
        firstname: (currentuser.upn.split('@'))[0],
        lastname: currentuser.Surname_name,
        avatar: currentuser.upn.substring(0,2).toUpperCase(),
        roles: [{"title":"Master User","description":"Allowed to see changelog and edit forecasts","permissions":["Read forecasts","Create forecasts","Edit forecasts","Read changelog","Read summary","Write Product","Write Product"]}]
    });

}*/

export default router
