// Vuex App(-Context) Module
import { HISTORY_STATE, stateMaxOrderNumber } from '../../helper/historyStates'

const state = {
    context: {}
}

if (console != null) {
    let _log = console.log;
    let _error = console.error;
    let _warning = console.warn;

    console.log = function log() {
        _log.apply(console, arguments)
    }

    console.error = function error() {
        _error.apply(console, arguments)
    }

    console.warn = function warn() {
        _warning.apply(console, arguments)
    }
}

const getters = {
    appContext(state) {
        return state.context
    },
    opportunityDaysFilter(state) {
        // check if app context is properly set
        if ("opportunityDaysFilter" in state.context)
            return state.context.opportunityDaysFilter
        // if not, return a default value of 30 days
        return 30
    },
    /**
     * Try to get a mail template for the groups, path and opportunity data
     * Payload: { cc: {}, cisco: {}, data: {}, path: "" } 
     */
    getMailTemplate: (s) => (payload) => {
        let group = (payload.cc != null) ? "cc" : "cisco"

        let maxOrderNumber = stateMaxOrderNumber(payload.data)
        let historyStateKey;

        for (const [key, value] of Object.entries(HISTORY_STATE)) {
            if (value == maxOrderNumber) {
                historyStateKey = `${key}`
            }
        }

        let state;
        if (payload.additionalState == '_allgemein_dv' || payload.additionalState == '_allgemein') {
            state = 'qualifiziert'
        } else {
            state = (historyStateKey || "neu").toLowerCase()
        }

        if (payload.additionalState != null) {
            state += payload.additionalState
        }

        let path = `${payload.path}/${state}/${group}`

        let paths = s.context.mailTemplates.filter(m => m.path == path)
        if (paths.length < 1)
            console.error("Mail template cannot be found for: ", path)
        return paths[0]
    }
}

const mutations = {
    setAppContext(state, appContext) {
        state.context = appContext
    }
}

const actions = {};

export const app = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
}