// Manufacturer Service Functions

import api from '../api'
import store from '../../store'
import { userFeedbackService } from '..'

export const opportunityState = {
    getAll,
}

const API_URL = process.env.VUE_APP_AWS_SERVICE_OPPORTUNITY_URL + '/api/opportunitystate/';


// HTTP GET to fetch all manufacturers and returns them if they exists.
// Otherwise it will return an empty array.
async function getAll() {
    await api.get(API_URL)
        .then((response) => {
            store.dispatch('opportunityState/setItems', response.data);
        })
        .catch((error) => {
            console.error(error);
            userFeedbackService.error(error);
        })
}



