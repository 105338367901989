// Manufacturer Service Functions

import api from '../api'
import store from '../../store'
import { userFeedbackService } from '..'

const API_APP_CONTEXT_URL = process.env.VUE_APP_AWS_SERVICE_API_URL + '/api/appcontext/';

export const appService = {
    // GET the app-context
    getAppContext: async () => {
        await api.get(API_APP_CONTEXT_URL)
            .then((response) => {
                store.commit('app/setAppContext', response.data);
            })
            .catch((error) => {
                console.error(error);
                userFeedbackService.error(error);
            })
    },

    // Save changes to Appcontext
    save: (context) => {
        return api.post(API_APP_CONTEXT_URL, context)
    }
}