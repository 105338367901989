//----------- DOCUMENTATION --------------------
// https://github.com/aws-amplify/amplify-js/blob/main/packages/amplify-ui-components/src/common/Translations.ts

import { Translations } from "@aws-amplify/ui-components";

const language = {
    de : {
        [Translations.SIGN_IN_HEADER_TEXT]: "Anmelden",
        [Translations.SIGN_IN_ACTION]: "Anmelden",

        [Translations.CHANGE_PASSWORD_ACTION]: "Ändern",
        [Translations.CHANGE_PASSWORD]: "Passwort ändern",
      
        [Translations.EMAIL_LABEL]: "Email",
        [Translations.EMAIL_PLACEHOLDER]: "Email",
      
        [Translations.FORGOT_PASSWORD_TEXT]: "Passwort vergessen?",
        [Translations.RESET_PASSWORD_TEXT]: "Passwort zurücksetzen",
      
        [Translations.NEW_PASSWORD_LABEL]: "Neues Passwort",
        [Translations.NEW_PASSWORD_PLACEHOLDER]: "Neues Passwort",
      
        [Translations.PASSWORD_LABEL]: "Passwort",
        [Translations.PASSWORD_PLACEHOLDER]: "Passwort",
      
      
        [Translations.USERNAME_LABEL]: "Benutzername",
        [Translations.USERNAME_PLACEHOLDER]: "Benutzername",
      
        [Translations.USERNAME_LABEL]: "Benutzername",
        [Translations.USERNAME_PLACEHOLDER]: "Benutzername",
      
        [Translations.FAMILY_NAME_LABEL]: "Nachname",
        [Translations.FAMILY_NAME_PLACEHOLDER]: "Nachname",
      
        [Translations.NAME_LABEL]: "Vorname",
        [Translations.NAME_PLACEHOLDER]: "Vorname",
      
        [Translations.BACK_TO_SIGN_IN]: "Zurück zur Anmeldung",

        [Translations.RESET_YOUR_PASSWORD] : "Passwort zurücksetzen",
        [Translations.SEND_CODE]: "Code senden",
       
      }
}

export{
  language
}