// Vuex OpportunityState module

const state = {
    opportunityStates: [],
}

const actions = {
    setItems({ commit }, items) {
        commit('setItems', items);
    },    
}

const getters = {
    states(state){          
        return state.opportunityStates
    },

    findByOrderNumber: (state) => (orderNumber) => {
        for(let oppState of state.opportunityStates){
            if(oppState.orderNumber === orderNumber){
                return oppState
            }
        }
        return 0
    },

    getEditableStates(state){
        let editableStates = []
        for(let oppState of state.opportunityStates){
            if(oppState.orderNumber === 2 || oppState.orderNumber === 3){
                editableStates.push(oppState)
            }
        }
        return editableStates
    }

}

const mutations = {
    setItems(state, items) {
        state.opportunityStates = items;
    },
}


export const opportunityState = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state    
}