
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
import { Amplify } from "@aws-amplify/core";

export const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": process.env.VUE_APP_AWS_USER_POOL_ID,
    "aws_user_pools_web_client_id": process.env.VUE_APP_AWS_CLIENT_ID,
    "oauth": {}
};

Amplify.configure(awsmobile);

// Translate to german
import {language} from "../language/language.js"

Amplify.I18n.putVocabulariesForLanguage("de", language.de);
Amplify.I18n.setLanguage("de")