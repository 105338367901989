var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "amplify-authenticator",
        [
          _c("amplify-sign-in", {
            attrs: { slot: "sign-in", "hide-sign-up": true },
            slot: "sign-in",
          }),
        ],
        1
      ),
      _vm.$store.getters.getLoadState
        ? _c(
            "v-app",
            [
              _c("AppBar"),
              _c(
                "v-main",
                [
                  _c(
                    "v-fade-transition",
                    { attrs: { mode: "out-in" } },
                    [_c("router-view")],
                    1
                  ),
                  _c("UserFeedback"),
                  _c("sx-confirm-dialog"),
                ],
                1
              ),
              _c("HomeFooter"),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }