export * from './changelog.service'
export * from './opportunity/api.opportunity.service'
export * from './service/api.oppService.service'
export * from './service/api.skill.service'
export * from './service/api.app.service'
export * from './service/api.user.service'
export * from './customer/api.customer.service'
export * from './userFeedback.service'
export * from './service/api.oppService.service'
export * from './opportunity/api.opportunityState.service'
export * from './cisco/api.collection.service'

import { Auth } from "@aws-amplify/auth";
import axios from 'axios'

function AddAuthorizationHeader () {
    getToken().then((token) => {
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${token}`;
    });
 
}

async function getToken() {
    return (await Auth.currentSession())
        .getIdToken()
        .getJwtToken();
}


export {AddAuthorizationHeader};