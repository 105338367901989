import axios from 'axios'
/**
 * API-Headers get updated by the user-store on token & contractName mutation
 */
const api = axios.create({
    headers: {
        "Authorization": '',
        "Contract-Name": ''
    }
})

export default api