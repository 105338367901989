import store from '..';
import api from '../../services/api'
import { HISTORY_STATE, HISTORY_STATE_NAME_EXCEL } from '../../helper/historyStates';
import { userFeedbackService } from '../../services';
import { formatDate } from "../../helper/date.helper"

const API_URL = process.env.VUE_APP_AWS_SERVICE_API_URL + '/api/collection/oppandservice';

export default {
    state: () => ({
        isLoading: false,
        oppAndServices: []
    }),
    mutations: {
        setOppAndServices: (state, oppAndServices) => {
            for (let opp of oppAndServices) {
                if (opp.history == null)
                    opp.history = []

                // overwrite description and state from history states
                let neu = opp.history.find(
                    (h) => h.state.orderNumber == HISTORY_STATE.Neu
                );

                if (neu != null) {
                    opp.createdAt = neu.modifiedAt;
                    opp.description = neu.comment
                }

                // get history state with highest orderNumber
                let state = { orderNumber: 0 }
                opp.history.forEach(h => {
                    if (h.state.orderNumber > state.orderNumber) {
                        state = h.state;
                        opp.modifiedAt = h.modifiedAt;
                    }
                    if (h.state.orderNumber == HISTORY_STATE.Qualifiziert
                        || h.state.orderNumber == HISTORY_STATE.Abgelehnt) {
                        opp.stateDescription = h.comment
                    }
                })

                opp.state = state.name

                // set createdBy from history
                opp.history.forEach((historyState) => {
                    if (historyState.state.orderNumber == HISTORY_STATE.Neu) {
                        opp.createdBy = historyState.modifiedBy;
                    }

                    // Excel - History fields
                    if (historyState.state.orderNumber in HISTORY_STATE_NAME_EXCEL) {
                        let state = null;
                        state = HISTORY_STATE_NAME_EXCEL[historyState.state.orderNumber]

                        if (state) {
                            // Add to states object
                            if (opp.states) {
                                opp.states = Object.assign(opp.states, {
                                    [state]: {
                                        modifiedBy: historyState.modifiedBy,
                                        modifiedAt: formatDate(historyState.modifiedAt),
                                        comment: historyState.comment
                                    },
                                });
                                // Create states object
                            } else {
                                opp = Object.assign(opp, {
                                    states: {
                                        [state]: {
                                            modifiedBy: historyState.modifiedBy,
                                            modifiedAt: formatDate(historyState.modifiedAt),
                                            comment: historyState.comment
                                        },
                                    },
                                });
                            }
                        }
                    }
                });
            }

            oppAndServices
                // filter opportunities that are older than a number of days from appcontext
                .filter(opportunity => {
                    // Empty wonOrLostState object
                    if (opportunity.wonInformation === null) return opportunity
                    // Newversion or cloned any wonOrLostDate
                    if (opportunity.wonInformation.orderDate === null) return opportunity

                    let minDate = new Date()
                    minDate.setDate(minDate.getDate() - store.getters["app/opportunityDaysFilter"])
                    return new Date(opportunity.wonInformation.orderDate) > minDate
                })
                // comment .filter out and comment this in if you want to see all opportunities
                .sort((a, b) => new Date(b.modifiedAt) - new Date(a.modifiedAt))
            // commit to store
            state.oppAndServices = oppAndServices
        },

        updateOpportunity(state, opportunity) {
            // get history state with highest orderNumber
            const index = state.oppAndServices.map(item => item).findIndex(element => element.id == opportunity.id);
            let stateHistory = { orderNumber: 0 }
            opportunity.history.forEach(h => {
                if (h.state.orderNumber == HISTORY_STATE.Neu) {
                    opportunity.createdBy = h.modifiedBy;
                    opportunity.createdAt = h.modifiedAt;
                }
                if (h.state.orderNumber > stateHistory.orderNumber) {
                    stateHistory = h.state;
                    opportunity.modifiedAt = h.modifiedAt;
                }
                if (h.state.orderNumber == HISTORY_STATE.Qualifiziert
                    || h.state.orderNumber == HISTORY_STATE.Abgelehnt) {
                    opportunity.stateDescription = h.comment
                }
            })

            opportunity.state = stateHistory.name;

            state.oppAndServices[index] = opportunity;

            // Update UI
            state.oppAndServices = [...state.oppAndServices]
        },

        setOrderedServicesForExcel(state, oppAndService) {
            const index = state.oppAndServices.map(item => item).findIndex(element => element.id == oppAndService.opportunity.id);

            oppAndService.opportunity.services = oppAndService.services.orderedServices;


            state.oppAndServices[index] = oppAndService.opportunity;

            // Update UI
            state.oppAndServices = [...state.oppAndServices]

        },

        addItem(state, item) {
            let stateHistory = { orderNumber: 0 }
            item.history.forEach(h => {
                if (h.state.orderNumber == HISTORY_STATE.Neu) {
                    item.createdAt = h.modifiedAt;
                    item.createdBy = h.modifiedBy;
                }
                if (h.state.orderNumber > stateHistory.orderNumber) {
                    item.modifiedAt = h.modifiedAt;
                }
            })
            state.oppAndServices.push(item);
        },

        editOpportunityItem(state, item) {
            let opportunity = state.oppAndServices.filter(o => o.id == item.id)
            if (opportunity != null) {

                opportunity = Object.assign(opportunity, item)
                if (item.wonOrLostState) {
                    opportunity.wonOrLostState = item.wonOrLostState;
                }
            }
        },

        deleteOpportunity(state, item) {
            const index = state.oppAndServices.indexOf(item);
            state.oppAndServices.splice(index, 1);
        },

    },
    getters: {
        getOppAndServices(state) {
            return state.oppAndServices
        },

        getOpportunity: (state) => (id) => {
            return state.oppAndServices.find(opp => opp.id === id)
        },
    },
    actions: {
        async getAll({ state, commit }) {
            try {
                state.isLoading = true
                let response = await api.get(API_URL)
                commit('setOppAndServices', response.data);
                state.isLoading = false
                return response.data
            } catch (error) {
                console.error(error);
                state.isLoading = false
                userFeedbackService.error(error);
            }
        },

        addItem({ commit }, item) {
            commit('addItem', item);
        },

        updateOpportunity({ commit }, item) {
            commit('updateOpportunity', item);
        },

        editOpportunityItem({ commit }, item) {
            commit('editOpportunityItem', item);
        },

        deleteOpportunity({ commit }, item) {
            commit('deleteOpportunity', item);
        },
    }
}