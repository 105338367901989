import Vue from 'vue';
import axios from 'axios';
import './helper/filters';
import App from './App.vue';
import store from './store';
import "@aws-amplify/ui-vue";
import router from './router';
import './config/amplify.config';
import './config/ag-grid-vue.config';
import VueCurrencyInput from "vue-currency-input";

import eventBus from './services/eventBus';
import vuetify from './config/ui-framework.config';

Vue.use(VueCurrencyInput)
Vue.prototype.$http = axios
Vue.config.productionTip = false
Vue.prototype.$eventBus = eventBus

// auto register core components
const coreContext = require.context('./components/core', true, /\.vue$/i, 'lazy');
coreContext.keys().forEach(path => {
    const name = path.split('/').pop().split('.')[0];
    Vue.component(name, () => coreContext(path));
});

new Vue({
    store,
    router,
    vuetify,
    render: h => h(App),
}).$mount('#app');
