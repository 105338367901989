// Vuex OppService Module
const state = {
    oppServices: [],
    orderedServices: [],
    oppService: {
        id: null,
        opportunityID: null,
        services: [],
        orderedServices: [],
    }
}


const getters = {
    getItemServices: state => id => {
        let found = state.oppServices.find(item => {
            if (item.opportunityID === id) {
                return item
            }
        });
        try {
            return JSON.parse(JSON.stringify(found))
        } catch (err) {
            //console.log(err)
        }

    },

    getOrderedServices: state => id => {
        state.oppServices.find(item => {
            if (item.opportunityID === id) {
                if (item.orderedServices) {
                    state.orderedServices = item.orderedServices
                    return state.orderedServices
                } else {
                    // no orderedServices - copy the services     
                    item.orderedServices = JSON.parse(JSON.stringify(item.services))
                    state.orderedServices = item.orderedServices
                    return state.orderedServices
                }
            }
        })
        return state.orderedServices
    },

}

const mutations = {
    setItems(state, items) {
        state.oppServices = items;
    },

    addOppService(state, item) {
        state.oppServices.push(item)
    },

    deleteOppService(state, item) {
        const index = state.oppServices.indexOf(item);
        state.oppServices.splice(index, 1);
    },

    removeOppServiceItem(state, payload) {
        let oppService = state.oppServices.filter(service => service.id == payload.oppServiceId)
        if (oppService.length > 0)
            oppService[0] = oppService[0].services.filter(service => service != payload.service)
    },

    editOppServiceItem(state, item) {
        const index = state.oppServices.map(f => f.id).indexOf(item.id);
        state.oppServices[index].id = item.id;
        state.oppServices[index].opportunityID = item.opportunityID;
        state.oppServices[index].contractName = item.contractName;
        state.oppServices[index].services = item.services;
        state.oppServices[index].orderedServices = item.orderedServices;
        state.oppServices[index].isCloneOrNewversion = item.isCloneOrNewversion;
    }
}

const actions = {
    setItems({ commit }, items) {
        commit('setItems', items);
    },
    // Ugly name. We should split up both modules
    deleteOppService({ commit }, item) {
        commit('deleteOppService', item);
    },

    addOppService({ commit }, item) {
        commit('addOppService', item);
    },
    editOppServiceItem({ commit }, item) {
        commit('editOppServiceItem', item);
    }

};



export const oppService = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
}