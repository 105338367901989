// ChangeLog Service Functions

import api from './api'
import store from '../store'

import { userFeedbackService } from './'

export const changelogService = {
    get
}

const API_URL = '/api/changelog/';

async function get(fromDate, toDate) {
    store.dispatch('changelog/setLoading', true);
    await api.get(API_URL, {
        params: {
            fromDate: fromDate,
            toDate: toDate
        }
    })
    .then((response) => {
        store.dispatch('changelog/setItems', response.data);
    })
    .catch((error) => {
        console.error(error);
        userFeedbackService.error(error);
    })
    .finally(() => {
        store.dispatch('changelog/setLoading', false);
    })
}