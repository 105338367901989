// Vuex ChangeLog Module

const state = {
    isActive: false,
    color: "info",
    message: "Infomessage",
    timeout: 2000
}

const actions = {
    showUserFeedback({ commit }, userFeedback) {
        commit('showUserFeedback', userFeedback)
    },
    setIsUserFeedback({commit}, status) {
        commit('setIsUserFeedback', status)
    }
}

const mutations = {
        // Sets just the state of the user feedback.
        setIsUserFeedback(state, value) {
            state.isActive = value;
        },

        // Enables the userfeedback. More info about the 'userFeedback'
        // can be found in the component UserFeedback.vue
        showUserFeedback(state, userFeedback) {
            state.message = userFeedback.message;
            state.color = userFeedback.color;
            state.timeout = userFeedback.timeout;
            state.isActive = true;
        },
}

export const userFeedback = {
    namespaced: true,
    actions,
    mutations,
    state    
}