// Collection Service Functions

import api from '../api'
import store from '../../store'
import { userFeedbackService } from '..'

const API_APP_COLLECTION_URL = process.env.VUE_APP_AWS_SERVICE_API_URL + '/api/collection/';

export const collection = {
    // GET the collection
    getAll: async () => {
        try {
            let response = await api.get(API_APP_COLLECTION_URL + "oppandservice")
            store.commit('collection/setCollection', response.data);
            return response.data
        } catch(error) {
            console.error(error);
            userFeedbackService.error(error);
        }
    },
    // Post the information needed for document generation
    generateDocument: async (payload) => {
        try {
          const response = await api.post(
            API_APP_COLLECTION_URL + "generatedocument",
            payload,
            { responseType: "blob" }
          );
          const filename = response.headers['x-outputfilename'];
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        } catch (error) {
          console.error(error);
          // To display blob-type error message
          if (error.response && error.response.data instanceof Blob) {
            const reader = new FileReader();
            reader.onload = function () {
              const errorMessage = reader.result;
              userFeedbackService.error('Error generating document: ' + errorMessage);
            };
            reader.readAsText(error.response.data);
          } else {
            userFeedbackService.error('Error generating document: ' + error);
          }
        }
    }
}