var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "custom-icon",
      attrs: {
        width: "32.4856mm",
        height: "31.604332mm",
        viewBox: "0 0 32.4856 31.604332",
        version: "1.1",
        id: "svg5",
        "xml:space": "preserve",
        "inkscape:version": "1.2.2 (732a01da63, 2022-12-09)",
        "sodipodi:docname": "Zeichnung.svg",
        "xmlns:inkscape": "http://www.inkscape.org/namespaces/inkscape",
        "xmlns:sodipodi": "http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:svg": "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("defs", { attrs: { id: "defs2" } }, [
        _c("rect", {
          attrs: {
            x: "341.02356",
            y: "316.07062",
            width: "71.294128",
            height: "51.490204",
            id: "rect509",
          },
        }),
        _c("rect", {
          attrs: {
            x: "341.02356",
            y: "316.07062",
            width: "71.294128",
            height: "51.490204",
            id: "rect509-5",
          },
        }),
        _c("rect", {
          attrs: {
            x: "341.02356",
            y: "316.07062",
            width: "71.294128",
            height: "51.490204",
            id: "rect509-3",
          },
        }),
      ]),
      _c(
        "g",
        {
          attrs: {
            "inkscape:label": "Ebene 1",
            "inkscape:groupmode": "layer",
            id: "layer1",
            transform: "translate(-9.9432204,-83.810361)",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "g517",
                "inkscape:export-filename": "g517.svg",
                "inkscape:export-xdpi": "96",
                "inkscape:export-ydpi": "96",
              },
            },
            [
              _c("path", {
                staticStyle: { "stroke-width": "0.264583" },
                attrs: {
                  d: "m 70.67815,82.203873 h 28.781375 a 1.8706042,1.8706042 0 0 1 1.852085,1.865312 v 19.780255 a 1.8520833,1.8520833 0 0 1 -0.32279,1.05833 0.73554167,0.73554167 0 0 1 -0.17463,0.26459 0.69320833,0.69320833 0 0 1 -0.17462,0.1217 1.8520833,1.8520833 0 0 1 -1.193274,0.43657 H 70.67815 a 1.8706042,1.8706042 0 0 1 -1.852083,-1.85209 V 84.069185 A 1.8706042,1.8706042 0 0 1 70.67815,82.203873 Z M 70.59878,103.06362 80.330155,92.951248 70.59878,84.727998 Z M 81.795942,94.21331 71.397817,104.28336 H 98.6499 L 88.714796,94.21331 85.80438,96.650123 v 0 a 0.714375,0.714375 0 0 1 -0.912813,0 z M 90.156775,92.932727 99.5389,103.14564 V 84.638039 Z m -18.642542,-9.297459 13.805959,11.18923 13.197417,-11.18923 z",
                  id: "path11",
                  "inkscape:export-filename": "path11.svg",
                  "inkscape:export-xdpi": "96",
                  "inkscape:export-ydpi": "96",
                },
              }),
              _c("rect", {
                staticStyle: { fill: "#f9f9f9", "stroke-width": "0.287083" },
                attrs: {
                  id: "rect175",
                  width: "22.426294",
                  height: "16.033751",
                  x: "87.714058",
                  y: "85.827736",
                },
              }),
              _c(
                "text",
                {
                  staticStyle: {
                    "font-style": "normal",
                    "font-variant": "normal",
                    "font-weight": "normal",
                    "font-stretch": "normal",
                    "font-size": "12px",
                    "font-family": "Arial",
                    "-inkscape-font-specification": "'Arial, Normal'",
                    "font-variant-ligatures": "normal",
                    "font-variant-caps": "normal",
                    "font-variant-numeric": "normal",
                    "font-variant-east-asian": "normal",
                    "white-space": "pre",
                    "shape-inside": "url(#rect509)",
                    display: "inline",
                    fill: "#000000",
                  },
                  attrs: {
                    "xml:space": "preserve",
                    transform:
                      "matrix(1.5659733,0.01192027,-0.01460779,1.5139901,-441.52883,-398.69636)",
                    id: "text507",
                  },
                },
                [
                  _c(
                    "tspan",
                    {
                      attrs: {
                        x: "341.02344",
                        y: "326.87937",
                        id: "tspan1051",
                      },
                    },
                    [
                      _c(
                        "tspan",
                        {
                          staticStyle: {
                            "font-weight": "bold",
                            "font-stretch": "condensed",
                            "-inkscape-font-specification":
                              "'Arial, Bold Condensed'",
                          },
                          attrs: { id: "tspan1049" },
                        },
                        [_vm._v("DV")]
                      ),
                    ]
                  ),
                ]
              ),
              _c("path", {
                staticStyle: { "stroke-width": "0.264583" },
                attrs: {
                  d: "m 11.795304,83.81036 h 28.781374 a 1.8706042,1.8706042 0 0 1 1.85209,1.865312 v 19.780248 a 1.8520833,1.8520833 0 0 1 -0.32279,1.05833 0.73554167,0.73554167 0 0 1 -0.17463,0.26459 0.69320833,0.69320833 0 0 1 -0.17462,0.1217 1.8520833,1.8520833 0 0 1 -1.19328,0.43657 H 11.795304 A 1.8706042,1.8706042 0 0 1 9.9432206,105.48502 V 85.675672 A 1.8706042,1.8706042 0 0 1 11.795304,83.81036 Z m -0.07937,20.85974 9.731375,-10.112365 -9.731375,-8.22325 z M 22.913096,95.819797 12.514971,105.88984 H 39.767058 L 29.83195,95.819797 26.921534,98.25661 v 0 a 0.714375,0.714375 0 0 1 -0.912813,0 z m 8.360833,-1.280583 9.382129,10.212906 V 86.244526 Z m -18.642542,-9.297459 13.805959,11.18923 13.197422,-11.18923 z",
                  id: "path11-8",
                  "inkscape:export-filename": "path11.svg",
                  "inkscape:export-xdpi": "96",
                  "inkscape:export-ydpi": "96",
                },
              }),
              _c("rect", {
                staticStyle: { fill: "#f9f9f9", "stroke-width": "0.287083" },
                attrs: {
                  id: "rect175-0",
                  width: "22.426294",
                  height: "16.033751",
                  x: "14.99817",
                  y: "99.380943",
                },
              }),
              _c(
                "text",
                {
                  staticStyle: {
                    "font-style": "normal",
                    "font-variant": "normal",
                    "font-weight": "normal",
                    "font-stretch": "normal",
                    "font-size": "12px",
                    "font-family": "Arial",
                    "-inkscape-font-specification": "'Arial, Normal'",
                    "font-variant-ligatures": "normal",
                    "font-variant-caps": "normal",
                    "font-variant-numeric": "normal",
                    "font-variant-east-asian": "normal",
                    "white-space": "pre",
                    "shape-inside": "url(#rect509-3)",
                    display: "inline",
                    fill: "#000000",
                  },
                  attrs: {
                    "xml:space": "preserve",
                    transform:
                      "matrix(1.5659733,0.01192027,-0.01460779,1.5139901,-514.24471,-385.14316)",
                    id: "text507-2",
                  },
                },
                [
                  _c(
                    "tspan",
                    {
                      attrs: {
                        x: "341.02344",
                        y: "326.87937",
                        id: "tspan1055",
                      },
                    },
                    [
                      _c(
                        "tspan",
                        {
                          staticStyle: {
                            "font-weight": "bold",
                            "font-stretch": "condensed",
                            "-inkscape-font-specification":
                              "'Arial, Bold Condensed'",
                          },
                          attrs: { id: "tspan1053" },
                        },
                        [_vm._v("DV")]
                      ),
                    ]
                  ),
                ]
              ),
              _c("path", {
                staticStyle: { "stroke-width": "0.264583" },
                attrs: {
                  d: "m 71.109708,113.46756 h 28.781377 a 1.8706042,1.8706042 0 0 1 1.852075,1.86531 v 19.78026 a 1.8520833,1.8520833 0 0 1 -0.32279,1.05833 0.73554167,0.73554167 0 0 1 -0.17463,0.26459 0.69320833,0.69320833 0 0 1 -0.17462,0.1217 1.8520833,1.8520833 0 0 1 -1.193265,0.43657 H 71.109708 a 1.8706042,1.8706042 0 0 1 -1.852083,-1.85209 v -19.80936 a 1.8706042,1.8706042 0 0 1 1.852083,-1.86531 z m -0.07937,20.85975 9.731377,-10.11237 -9.731377,-8.22325 z m 11.197167,-8.85031 -10.39813,10.07005 h 27.25208 l -9.9351,-10.07005 -2.91042,2.43681 v 0 a 0.714375,0.714375 0 0 1 -0.91281,0 z m 8.36083,-1.28058 9.38212,10.21292 v -18.50761 z m -18.642544,-9.29746 13.805954,11.18923 13.19742,-11.18923 z",
                  id: "path11-0",
                  "inkscape:export-filename": "path11.svg",
                  "inkscape:export-xdpi": "96",
                  "inkscape:export-ydpi": "96",
                },
              }),
              _c("rect", {
                staticStyle: { fill: "#f9f9f9", "stroke-width": "0.25055" },
                attrs: {
                  id: "rect175-5",
                  width: "17.081709",
                  height: "16.033751",
                  x: "89.40316",
                  y: "117.09142",
                },
              }),
              _c(
                "text",
                {
                  staticStyle: {
                    "font-style": "normal",
                    "font-variant": "normal",
                    "font-weight": "normal",
                    "font-stretch": "normal",
                    "font-size": "12px",
                    "font-family": "Arial",
                    "-inkscape-font-specification": "'Arial, Normal'",
                    "font-variant-ligatures": "normal",
                    "font-variant-caps": "normal",
                    "font-variant-numeric": "normal",
                    "font-variant-east-asian": "normal",
                    "white-space": "pre",
                    "shape-inside": "url(#rect509-5)",
                    display: "inline",
                    fill: "#000000",
                  },
                  attrs: {
                    "xml:space": "preserve",
                    transform:
                      "matrix(1.3246816,0.01154056,-0.01235696,1.4657627,-357.78833,-351.53344)",
                    id: "text507-6",
                  },
                },
                [
                  _c(
                    "tspan",
                    {
                      attrs: {
                        x: "341.02344",
                        y: "326.87937",
                        id: "tspan1059",
                      },
                    },
                    [
                      _c(
                        "tspan",
                        {
                          staticStyle: {
                            "font-weight": "bold",
                            "-inkscape-font-specification": "'Arial, Bold'",
                          },
                          attrs: { id: "tspan1057" },
                        },
                        [_vm._v("CI")]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }