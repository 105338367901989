// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!../node_modules/@ag-grid-community/core/dist/styles/ag-grid.min.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!../node_modules/@ag-grid-community/core/dist/styles/ag-theme-alpine.min.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, "#app {\n  background: initial !important;\n}\nhtml,\nbody {\n  overflow: auto !important;\n}\n:root {\n  --amplify-secondary-tint: #ff6a6a;\n}\n.extra-padding {\n  padding-bottom: 96px !important;\n  padding-top: 96px !important;\n}\n@media screen and (max-width: 959px) {\n.extra-padding {\n    padding-top: 48px !important;\n    padding-bottom: 48px !important;\n}\n}\n.main {\n  border-radius: 4px;\n  background-color: #f9f9fceb !important;\n}\na {\n  text-decoration: none !important;\n}\n.pointer {\n  cursor: pointer;\n}\n.sx-padding {\n  padding: 25px;\n}\n.sx-flex-buttons {\n  display: flex;\n}\n.sx-flex-buttons > button {\n  margin-left: 10px;\n}\n.sx-flex-buttons > button:first-child {\n  margin-left: 0px !important;\n}\n.sx-table-cell-bool-true {\n  width: 10px;\n  height: 10px;\n  margin-top: 15px;\n  border-radius: 50%;\n  background-color: #7fd47f;\n}\n.sx-table-cell-bool-false {\n  width: 10px;\n  height: 10px;\n  margin-top: 15px;\n  border-radius: 50%;\n  background-color: #d47f7f;\n}\n.icon-center {\n  margin: 0 auto !important;\n  margin-bottom: 9px !important;\n}\n.sx-vertical-layout {\n  display: flex;\n  flex-direction: column;\n}", ""]);
// Exports
module.exports = exports;
