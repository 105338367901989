import Vue from 'vue'
import Vuex from 'vuex'

import filters from '../helper/filters'
import { group } from './group.module'
import { changelog } from './changelog.module'
import { customer } from './customer/customer.module'
import { skill } from './service/skill.module'
import { app } from './service/app.module'
import { service } from './service/service.module'
import { opportunity } from './opportunity/opportunity.module'
import { userFeedback } from './userFeedback.module'
import { oppService } from './service/oppService.module'
import { user } from './user.module'

import { opportunityState } from './opportunity/opportunityState.module'

Vue.use(Vuex)

/**
 * Auto load store-modules
 */
const context = require.context('./store-modules', true, /\.module\.js$/i);
let storeModules = {
    changelog,
    customer,
    skill,
    app,
    service,
    opportunity,
    userFeedback,
    oppService,
    user,
    group,
    opportunityState,
}

context.keys().forEach(file => {
    let name = file.replace(/\.module\.js$/i, "").replace('./', "");
    storeModules[name] = context(file).default || context(file)
    storeModules[name].namespaced = true
});

export default new Vuex.Store({
    state: {
        userInfo: {
            firstname: "",
            lastname: "",
            fullname: "",
            email: "",
            avatar: "",
            groups: [],
        },
        load: false,
        // Data for cloned and newversion
        clonedOrNewVersionData: {
            state: false,
            oldOpportunity: Object,
            isNewVersion: false,
        }
    },
    getters: {
        hasPermission: (state) => (permission) => {
            var perms = [];
            state.userInfo.groups.forEach(role => {
                perms = perms.concat(role.permissions);
            });
            return perms.includes(permission);
        },

        // Checks if the current users groups match the parameter roleTitle.
        // e.g. hasRole("Master User")
        hasRole: (state) => (roleTitle) => {
            return state.userInfo.groups.map(x => x.title.toLowerCase()).includes(roleTitle.toLowerCase());
        },

        getLoadState(state) {
            return state.load
        },

        getMailText: () => (text, scope) => {
            if (text == null) return null
            Object.keys(scope).forEach(key => {
                text = text.replace(new RegExp(`{${key}}`, "g"), scope[key])
            })
            text = text.replace(new RegExp('\\n', "g"), '%0D%0A')
            return text
        }
    },

    mutations: {
        setUserInfo(state, { firstname, lastname, fullname, email, avatar, groups }) {
            state.userInfo.firstname = firstname;
            state.userInfo.lastname = lastname;
            state.userInfo.fullname = fullname;
            state.userInfo.email = email;
            state.userInfo.avatar = avatar;
            state.userInfo.groups = groups;
        },

        setLoadState(state, loadState) {
            state.load = loadState
        },

        setClonedOrNewVersionData(state, object) {
            state.clonedOrNewVersionData = object;
        }
    },
    actions: {
        setLoadState({ commit }, loadState) {
            commit('setLoadState', loadState)
        },

        setUserInfo({ commit }, user) {
            commit('setUserInfo', user)
        },

        /* eslint-disable no-unused-vars */
        sortByKey({ state }, { data, key }) {
            return data.sort(function (a, b) {
                if (a[key] < b[key]) { return -1; }
                if (a[key] > b[key]) { return 1; }
                return 0;
            })
        },

        openMail({ getters }, mailData) {
            let data = Object.assign({}, mailData.scope)
            if ("totalCost" in data)
                data.totalCost = filters.number(data.totalCost, "0,0.00 $")
            if ("startDate" in data)
                data.startDate = new Date(data.startDate).toISOString().split("T")[0].split('-').reverse().join('.')
            if ("createdAt" in data)
                data.createdAt = new Date(data.createdAt).toISOString().split("T")[0].split('-').reverse().join('.')
            if ("modifiedAt" in data)
                data.modifiedAt = new Date(data.modifiedAt).toISOString().split("T")[0].split('-').reverse().join('.')

            let url = `mailto:${getters.getMailText(mailData.template.receiver, data)}`
                + `?subject=${getters.getMailText(mailData.template.subject, data)}`;

            let ccText = getters.getMailText(mailData.template.ccReceiver, data);
            if (ccText != null)
                url += '&cc=' + ccText

            let bccText = getters.getMailText(mailData.template.bccReceiver, data)
            if (bccText != null)
                url += '&bcc=' + bccText

            url += `&body=${getters.getMailText(mailData.template.body, data)}`

            url = url.substring(0, Math.min(2000, url.length))

            window.open(url, '_self')
        }
    },
    modules: storeModules
})

/**
* <script>
* this.$store.dispatch("user/onActionDispatched")
* this.$store.commit("user/changeUsername")
* this.$store.getters["user/username"]
* 
* <template>
* {{$store.getters["user/username"]}}
*
* from: ./store-modules/user-module.js
* 
* Name of the file automatically becomes namespace of module, 
* in this case "user"
*/

