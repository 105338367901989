// Enumerations and app specific constants

const HISTORY_STATE = {
  Neu: 1,
  Qualifiziert: 2,
  Abgelehnt: 3,
  BitteUmFreigabe: 4,
  Freigegeben: 5,
  Gewonnen: 6,
  Verloren: 7,
  Ersetzt: 8,
};

const HISTORY_STATE_NAME = {
  Neu: "Neu",
  Qualifiziert: "Qualifiziert",
  Abgelehnt: "Abgelehnt",
  BitteUmFreigabe: "BitteUmFreigabe",
  Freigegeben: "Freigegeben",
  Gewonnen: "Gewonnen",
  Verloren: "Verloren",
  Ersetzt: "Ersetzt",
};

const HISTORY_STATE_NAME_EXCEL = {
  2: "qualified",
  3: "declined",
  4: "request",
  5: "approved",
  6: "won",
  7: "lost",
  8: "replaced"
}

function stateMaxOrderNumber(opportunity) {
  return Math.max.apply(
    Math,
    opportunity.history.map((o) => {
      return o.state.orderNumber;
    })
  );
}

/**
 * Returns the history state with the highest orderNumber
 * @param {*} opportunity the opportunity to process
 * @returns history: { state: {}, ... }
 */
function getMaxHistoryEntry(opportunity) {
  let historyEntry = { state: { orderNumber: 0 } }
  opportunity.history.forEach(h => {
    if (h.state.orderNumber > historyEntry.state.orderNumber)
      historyEntry = Object.assign({}, h)
  })
  return historyEntry
}

/**
 * Get an entry of the history array by orderNumber,
 * usage:
 * getHistory(HISTORY_STATE.Neu)
 */
function getHistory(opportunity, historyState) {
  if (opportunity == null) return null;
  if (opportunity.history == null) return null;
  return getHistoryState(opportunity.history, historyState)
}

function getHistoryState(historyArr, historyState) {
  return historyArr.find(
    (h) => h.state.orderNumber == historyState
  );
}

export {
  HISTORY_STATE,
  HISTORY_STATE_NAME,
  HISTORY_STATE_NAME_EXCEL,
  stateMaxOrderNumber,
  getMaxHistoryEntry,
  getHistoryState,
  getHistory
}