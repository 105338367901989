export const dateHelper = {
    formatYYYYMMDD,
    formatYYYYMM
}

function formatYYYYMMDD(dateString) {
    var date = new Date(dateString);

    var month = '' + (date.getMonth() + 1);
    var day = '' + date.getDate();
    var year = date.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

function formatYYYYMM(dateString) {
    var date = new Date(dateString);

    var month = '' + (date.getMonth() + 1);
    var year = date.getFullYear();

    if (month.length < 2)
        month = '0' + month;

    return [year, month].join('-');
}

function formatDate(date) {
    if (!date) return null;

    const datePart = date.split("T")[0];
    const [year, month, day] = datePart.split("-");

    return `${day}.${month}.${year}`;
}

export { formatDate, formatYYYYMM }