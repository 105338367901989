// Manufacturer Service Functions

import store from '../../store'
import api from '../api'
import { userFeedbackService } from '..'

export const opportunityService = {
    getAll,
    getOne,
    post,
    del,
    deleteById,
    patch,
    postNewVersion,
    postClone,
    patchHistoryRecord,
    getById,
    setIsEdited,
    setApprovalId,
    setWonInformation,
    removeHistoryRecord
}

const API_URL = process.env.VUE_APP_AWS_SERVICE_OPPORTUNITY_URL + '/api/opportunity/';


// HTTP GET to fetch all manufacturers and returns them if they exists.
// Otherwise it will return an empty array.
async function getAll() {
    await api.get(API_URL)
        .then((response) => {
            store.dispatch('opportunity/setItems', response.data);
        })
        .catch((error) => {
            console.error(error);
            userFeedbackService.error(error);
        })
}

// HTTP GET to fetch a single opportunity and returns it if it exists.
// Otherwise it will return null.
async function getOne(id) {
    let result = null;
    await api.get(API_URL.concat(id))
        .then((response) => {
            store.dispatch("opportunity/setOpportunity", {
                id: response.data.id,
                createdAt: response.data.createdAt,
                createdBy: response.data.createdBy,
                modifiedAt: response.data.modifiedAt,
                modifiedBy: response.data.modifiedBy,
                name: response.data.name,
                customerName: response.data.customerName,
                state: response.data.state,
                description: response.data.description,
                totalCost: response.data.totalCost,
                stateDescription: response.data.stateDescription,
                approvalId: response.data.approvalId
            });
        })
        .catch((error) => {
            console.error(error);
            userFeedbackService.error(error);
        })

    return result;
}

async function getById(id) {
    let result = null
    await api.get(`${API_URL}${id}`)
        .then((response) => {
            result = response.data
            store.dispatch('opportunity/editOpportunityItem', response.data);
        })
        .catch((error) => {
            console.error(error);
            userFeedbackService.error(error);
        })
    return result
}
// ersetzen mit setWonInformation
async function patch(id, wonOrLost) {
    await api.patch(`${API_URL}${id}`, wonOrLost).then(response => {

        if (response.data) {
            store.dispatch('opportunity/editOpportunityItem', response.data);
            userFeedbackService.success('Opportunity modified successfully');
        }
    })
        .catch((error) => {
            console.error(error);
            userFeedbackService.error('Error modifying Opportunity: ' + error);
        })
}

async function setWonInformation(id, wonInformation) {
    await api.patch(`${API_URL}${id}/setWonInformation`, wonInformation).then(response => {

        if (response.data) {
            store.dispatch('oppAndService/updateOpportunity', response.data);
            userFeedbackService.success('Opportunity modified successfully');
        }
    })
        .catch((error) => {
            console.error(error);
            userFeedbackService.error('Error modifying Opportunity: ' + error);
        })
}

async function setIsEdited(id) {
    await api.patch(`${API_URL}${id}/setIsEdited`).then(response => {

        if (response.data) {
            store.dispatch('oppAndService/updateOpportunity', response.data);
            userFeedbackService.success('Opportunity is set to edited successfully');
        }
    })
        .catch((error) => {
            console.error(error);
            userFeedbackService.error('Error modifying Opportunity: ' + error);
        })
}

async function setApprovalId(id, approvalId) {
    let isSuccess = false; // Default to false
    await api.patch(`${API_URL}${id}/setApprovalId`, approvalId).then(response => {

        if (response.data) {
            store.dispatch('oppAndService/updateOpportunity', response.data);
            userFeedbackService.success('Opportunity Approval Id is set successfully');
            isSuccess = true; // Update on success
        }
    })
        .catch((error) => {
            // Check if the error response has a data property containing the message
            const errorMessage = error.response && error.response.data ? error.response.data : 'Error setting Opportunity Approval Id';
            console.error(error);
            userFeedbackService.error(errorMessage);
        })
    return isSuccess; // Return the success status
}

// HTTP POST to create a opportunity and returns the result.
async function post(opportunity) {
    let payload = Object.assign({}, opportunity);

    let result = null
    await api.post(API_URL, payload)
        .then((response) => {
            result = response.data;

            if (payload.id == "00000000-0000-0000-0000-000000000000") {
                store.dispatch('oppAndService/addItem', response.data);
                userFeedbackService.success('Opportunity created successfully');
            }
            else {
                store.dispatch('oppAndService/updateOpportunity', response.data);
                userFeedbackService.success('Opportunity modified successfully');
            }
        })
        .catch((error) => {
            console.error(error);
            userFeedbackService.error('Error creating Opportunity: ' + error);
        })

    return result;
}

async function postNewVersion(opportunity) {
    // Update the state of the old opportunity
    store.dispatch('oppAndService/updateOpportunity', opportunity);

    let result;
    const newVersionUri = "/newversion"
    await api.post(`${API_URL}${opportunity.id}${newVersionUri}`)
        .then((response) => {
            result = response.data;

            if (result) {
                store.dispatch('oppAndService/addItem', result);
                userFeedbackService.success('Opportunity duplicated successfully');
            }

        })
        .catch((error) => {
            //console.error(error);
            userFeedbackService.error('Error duplicating Opportunity: ' + error);
        })

    return result;
}

async function postClone(opportunity) {

    let result;
    const cloneUri = "/clone"
    try {
        let response = await api.post(`${API_URL}${opportunity.id}${cloneUri}`)

        result = response.data;

        if (result) {
            store.dispatch('oppAndService/addItem', result);
            userFeedbackService.success('Opportunity cloned successfully');
        }

    } catch (error) {
        //console.error(error);
        userFeedbackService.error('Error cloning Opportunity: ' + error);
    }

    return result;
}


// HTTP DELETE to delete a opportunity and returns the result.
async function del(id) {
    let result = null
    await api.delete(API_URL.concat(id))
        .then((response) => {
            result = response.data;
            store.dispatch('oppAndService/deleteOpportunity', result);
            userFeedbackService.error('Reset or Error - Delete element!');
        })
        .catch((error) => {
            console.error(error);
            userFeedbackService.error('Error deleting Opportunity: ' + error);
        })

    return result;
}

/**
 * Delete an Opportunity by Id
 * @param {*} id the Opportunity Id
 * @returns the result body or null
 */
async function deleteById(id) {
    try {
        return await api.delete(API_URL.concat(id))
    } catch (error) {
        console.error(error);
        userFeedbackService.error('Error deleting Opportunity: ' + error);
    }
    return null;
}

/**
 * Add a new State to an Opportunity by Id
 * @param {*} id the Opportunity Id
 */
async function patchHistoryRecord(id, oppState) {
    await api.patch(`${API_URL}${id}/addhistoryrecord`, oppState).then((response) => {
        store.dispatch('oppAndService/updateOpportunity', response.data);
        userFeedbackService.success(`Status wurde auf ${oppState.state.name} gesetzt`);
    })
        .catch((error) => {
            console.error(error);
            userFeedbackService.error('Error adding new State: ' + error);
        })
}

//        [HttpPatch("{id}/removehistoryrecord")]
async function removeHistoryRecord(id) {
    await api.patch(`${API_URL}${id}/removehistoryrecord`).then((response) => {
        store.dispatch('oppAndService/updateOpportunity', response.data);
        userFeedbackService.success(`Status ersetzt wurde entfernt.`);
    })
        .catch((error) => {
            console.error(error);
            userFeedbackService.error('Error removing replaced State: ' + error);
        })
}