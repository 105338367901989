import Vue from 'vue'
import numeral from 'numeral'
// Docs: https://vuejs.org/v2/guide/filters.html


// Docs: http://numeraljs.com/
numeral.register('locale', 'de', {
    delimiters: {
        thousands: '.',
        decimal: ','
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't'
    },
    ordinal: () => {
        return '.';
    },
    currency: {
        symbol: '€'
    }
});
numeral.locale('de')

/* 
    From within a <template>:

    {{ price | formatNumber('0,0.00 $') }}
    -> 1.123.001,23 €
    
    {{ someNumber | formatNumber }}
    -> 1.001,23
    
    From within a <script>:

    this.$options.filters.formatNumber(price, '0,0.00 $')
*/

const filters = {
    number: (value, format = "0,0.00 $") => {
        return numeral(value).format(format);
    }
}

Vue.filter("formatNumber", filters.number);

export default filters