import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { LicenseManager } from "@ag-grid-enterprise/core";
import { ModuleRegistry } from '@ag-grid-community/core'; 
import { MenuModule } from '@ag-grid-enterprise/menu';
import { AgGridVue } from "@ag-grid-community/vue";
import Vue from 'vue';

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ExcelExportModule,
    ClipboardModule,
    MenuModule,
]);

Vue.component('ag-grid-vue', AgGridVue)
LicenseManager.setLicenseKey(process.env.VUE_APP_AG_GRID_LICENSE);