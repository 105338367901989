// Manufacturer Service Functions

import api from '../api'
import store from '../../store'
import { userFeedbackService } from '..'

export const skillService = {
    getAll,
    getOne,
    post,
    del
}

const API_URL = process.env.VUE_APP_AWS_SERVICE_SERVICE_URL + '/api/skill/';

// HTTP GET to fetch all skills and returns them if they exists.
// Otherwise it will return an empty array.
async function getAll() {
    try {
        let response = await api.get(API_URL)
        let skills = await store.dispatch("sortByKey", { data: response.data, key: 'name' })
        store.dispatch('skill/setItems', skills);
    } catch(error) {
        console.error(error);
        userFeedbackService.error(error);
    }
}

// HTTP GET to fetch a single skill and returns it if it exists.
// Otherwise it will return null.
async function getOne(id) {
    let result = null;
    await api.get(API_URL.concat(id))
        .then((response) => {
            result = response.data;
        })
        .catch((error) => {
            console.error(error);
            userFeedbackService.error(error);
        })

    return result;
}

// HTTP POST to create a skill and returns the result.
function post(skill) {
    let payload = Object.assign({}, skill);
    return api.post(API_URL, payload)
}


// HTTP DELETE to delete a skill and returns the result.
function del(id) {
    return api.request({
        url:  API_URL,
        method: 'delete',
        data: {
            id: id
        }
    })
}