// Vuex Customer Module

import store from "..";
import { HISTORY_STATE } from "../../helper/historyStates";

// Enum of wonOrLost state
const States = Object.freeze({ Won: 0, Lost: 1, Replaced: 2 });

const state = {
    // CAUTION! The opportunity list is just for the prototype
    opportunities: [],
    States,
    isLost: false,
    opportunity: {
        id: null,
        createdAt: "",
        createdBy: "",
        modifiedAt: null,
        modifiedBy: "",
        name: "",
        customerName: "",
        state: "New",
        description: "",
        totalCost: null,
        stateDescription: "",
        approvalId: ""
    },

}

const actions = {
    setItems({ commit }, items) {
        commit('setItems', items);
    },
    setIsLost({ commit }, isLost) {
        commit('setIsLost', isLost)
    },
    // Ugly name. We should split up both modules
    deleteOpportunity({ commit }, item) {
        commit('deleteSkill', item);
    },

    setOpportunity({ commit }, items) {
        commit('setOpportunity', items);
    },
    editOpportunityItem({ commit }, item) {
        commit('editOpportunityItem', item);
    },
    editOpportunityHistory({ commit }, item) {
        commit('editOpportunityHistory', item)
    },
    addItem({ commit }, item) {
        commit('addItem', item);
    },

}

const getters = {
    opportunities(state) {

        if (state.isLost) {
            return state.opportunities.filter((opportunity) => {
                // get history state with highest orderNumber
                let state = { orderNumber: 0 }
                opportunity.history.forEach(h => {
                    if (h.state.orderNumber > state.orderNumber) {
                        state = h.state;
                        opportunity.modifiedAt = h.modifiedAt;
                    }
                    if (h.state.orderNumber == HISTORY_STATE.Qualifiziert
                        || h.state.orderNumber == HISTORY_STATE.Abgelehnt) {
                        opportunity.stateDescription = h.comment
                    }
                })

                opportunity.state = state.name

                if (opportunity.wonOrLostState) {
                    return opportunity.wonOrLostState.state === "Lost"
                }
            });
        }
        else {
            return state.opportunities
                // filter opportunities that are older than a number of days from appcontext
                .filter(
                    opportunity => {
                        // get history state with highest orderNumber
                        let state = { orderNumber: 0 }
                        opportunity.history.forEach(h => {
                            if (h.state.orderNumber > state.orderNumber) {
                                state = h.state;
                                opportunity.modifiedAt = h.modifiedAt;
                            }
                            if (h.state.orderNumber == HISTORY_STATE.Qualifiziert
                                || h.state.orderNumber == HISTORY_STATE.Abgelehnt) {
                                opportunity.stateDescription = h.comment
                            }
                        })

                        opportunity.state = state.name

                        if (opportunity.wonInformation) {
                            let minDate = new Date()
                            minDate.setDate(minDate.getDate() - store.getters["app/opportunityDaysFilter"])
                            return new Date(opportunity.wonInformation.orderDate) > minDate
                        }
                        else {
                            return opportunity
                        }


                    })
            // comment .filter out and comment this in if you want to see all opportunities
            //.sort((a, b) => new Date(b.modifiedAt) - new Date(a.modifiedAt));
        }

    },

    getOpportunity: (state) => (id) => {
        return state.opportunities.find(opp => opp.id === id)
    },

    states: (state) => {
        return state.States
    }
}

const mutations = {
    setItems(state, items) {
        state.opportunities = items;
    },

    setIsLost(state, isLost) {
        state.isLost = isLost;
    },

    deleteOpportunity(state, item) {
        const index = state.opportunities.indexOf(item);
        state.opportunities.splice(index, 1);
    },

    editOpportunityItem(state, item) {
        let opportunity = state.opportunities.filter(o => o.id == item.id)
        if (opportunity != null) {
            opportunity = Object.assign(opportunity, item)
            if (item.wonOrLostState) {
                opportunity.wonOrLostState = item.wonOrLostState;
            }
        }
    },

    addItem(state, item) {
        state.opportunities.push(item);
    },
}


export const opportunity = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
}