import store from '../store'

export const userFeedbackService = {
    error,
    info,
    success,
    warn,
}

function error(message) {
    store.dispatch('userFeedback/showUserFeedback', {
        color: 'error',
        message: message,
        timeout: 3000,
    })
}
function info(message) {
    store.dispatch('userFeedback/showUserFeedback', {
        color: 'info',
        message: message,
        timeout: 2000,
    })
}
function success(message) {
    store.dispatch('userFeedback/showUserFeedback', {
        color: 'success',
        message: message,
        timeout: 2000,
    })
}
function warn(message) {
    store.dispatch('userFeedback/showUserFeedback', {
        color: 'warning',
        message: message,
        timeout: 2000,
    })
}