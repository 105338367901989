import api from '../services/api'

const state = {
    users: [],
    groups: [],
    computacenterAuth: "Computacentergroup",
    ciscoAuth: "Ciscogroup",
    adminAuth: "Admingroup",
    ciscoSibeAuth: "CiscoSibegroup",
    authToken: null,
}

const actions = {
    setItems({ commit }, items) {
        commit('setItems', items);
    },

    setCustomer({ commit }, items) {
        commit('setCustomer', items);
    },
    editCustomerItem({ commit }, item) {
        commit('editCustomerItem', item);
    }
}

const getters = {

    users(state) {
        return state.users.sort((a, b) => a.userName - b.userName)
    },

    groups(state) {
        return state.groups
    },

    getGroups(state) {
        return state.groups
    },

    getAuthToken(state) {
        return state.authToken
    },

    getComputacenterGroup(state) {
        return state.groups.find(group => group === state.computacenterAuth)
    },

    getCiscoGroup(state) {
        return state.groups.find(group => group === state.ciscoAuth)
    },

    getAdminGroup(state) {
        return state.groups.find(group => group === state.adminAuth)
    },

    getCiscoSibeGroup(state) {
        return state.groups.find(group => group === state.ciscoSibeAuth)
    }

}

const mutations = {
    setItems(state, items) {
        state.groups = items;
    },

    setUsers(state, items) {
        state.users = items
    },

    setGroups(state, items) {
        state.groups = items
    },

    setAuthToken(state, token) {
        state.authToken = token
        api.defaults.headers["Authorization"] = `Bearer ${state.authToken}`
    }
}


export const user = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
}